/* @import url('https://fonts.googleapis.com/css2?family=Alata&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Alata&family=Inter&display=swap');

body{
  font-family: Alata;
  color: black;
}
a{
  text-decoration: none;
  color: #4A4646;
}
a:hover{
  color: black;
}
.font-inter{
  font-family: Inter;
}
.font-13{
  font-size: 13px;
}
.font-14{
  font-size: 14px;
}
.font-15{
  font-size: 15px;
}
.font-16{
  font-size: 16px;
}
.font-20{
  font-size: 20px;
}
.font-26{
  font-size: 26px;
}
.font-36{
  font-size: 36px;
}
.font-48{
  font-size: 48px;
}
.topgap10{
  margin-top: 10px;
}
.topgap20{
  margin-top: 20px;
}
.topgap30{
  margin-top: 30px;
}
.topgap40{
  margin-top: 40px;
}
.topgap70{
  margin-top: 70px;
}
.topgap100{
  margin-top: 100px;
}
.topgap20Neg{
  margin-top: -20px;
}
.topgap200Neg{
  margin-top: -200px;
}
.bottomgap20{
  margin-bottom: 20px;
}
.leftgap20{
  margin-left: 20px;
}
.leftgap40Neg{
  margin-left: -40px;
}
.center-align{
  text-align: center;
}
.right-align{
  float: right;
}
.color-gray{
  color: #4A4646;
}
.kw-container{
  background-color: white;
  padding: 30px 60px;
}
.sunIcon{
  margin-top: 10px;
  margin-left: 223px;
}
.mainHeading{
  font-size: 48px;
}
.yoga{
  font-size: 48px;
  background-color: #DCF2AD;
  padding: 0 5px;
  margin-right: 10px;
}
.ayurveda{
  font-size: 48px;
  background-color: #BED6E4;
  padding: 0 5px;
  margin-right: 10px;
}
.naturopathy{
  font-size: 48px;
  background-color: #EFD6AA;
  padding: 0 5px;
}
.grayContainer{
  background-color: #EDEDED;
  /* height: 100vh; */
  margin-top: -155px;
  padding: 160px 60px 20px 60px;
}
.provideLineV{
  display: none;
}
.carousel{
  background-color: #F2F1F1;
  border-radius: 40px;
  padding: 30px 0 30px 200px;
}
.reviewName{
  font-size: 20px;
  margin-top: 0px;
}
.reviewDate{
  font-size: 10px;
  color: #4A4646;
}
.reviewPara{
  font-size: 16px;
  color: #4A4646;
  margin-bottom: 30px;
}
.reviewImg{
  width: 100%;
}
.form-container{
  background-color: #EFD6AA;
  border-radius: 40px;
  padding: 40px 20px;
  margin-bottom: 50px;
  width: 80%;
  box-shadow: 8px 12px 4px rgb(0, 0, 0, 0.2);
}
.kw-input{
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
}
.kw-btn{
  border: 1px solid #4A4646;
  background-color: transparent;
  border-radius: 20px;
  padding: 5px;
  width: 90%;
  margin-left: 10px;
}
.class-block{
  /* background-image: url(assets/Images/classBlock.svg);
  background-repeat: no-repeat;
  background-size: contain; */
  /* width: 100%;
  height: 100%; */
  border: 5px solid #EDEDED;
  background-color: transparent;
  border-radius: 40px;
  padding: 30px 35px;
  /* margin-bottom: 200px; */
}
.time-block{
  border-radius: 20px;
  padding: 15px 0 50px 15px;
}
.feeBlock{
  background-color: #F6DB6A;
  padding: 14px 90px;
  color: black;
  font-size: 26px;
  text-align: center;
  border-radius: 10px;
  margin-bottom: 122px;
}
.footer{
  padding: 20px 60px;
  background-color: #EDEDED;
}
.greenText{
  text-align: center;
  font-size: 13px;
  color: green;
  margin-top: 6px;
}

@media(min-width:300px) and (max-width:1100px){
  .media-hidden{
    display: none;
  }
  .center-align{
    text-align: start;
  }
  .sunIcon{
    margin-top: 10px;
    margin-left: 0;
  }
  .kw-container{
    padding: 30px;
  }
  .grayContainer{
    background-color: #EDEDED;
    height: 100%;
    margin-top: 0;
    padding: 20px 30px;
  }
  .yoga{
    display: inline-block;
    margin-bottom: 10px;
  }
  .ayurveda{
    display: inline-block;
    margin-bottom: 10px;
  }
  .naturopathy{
    display: inline-block;
  }
  .provideLineV{
    display: inline-block;
  }
  .provideLineH{
    display: none;
  }
  .leftgap40Neg{
    margin-left: 0;
  }
  .carousel{
    padding: 10px;
  }
  .reviewImg{
    width: 20%;
  }
  .topgap10Media{
    margin-top: 10px;
  }
  .time-block{
    margin-top: 20px;
  }
  .class-block{
    background-image: none;
    background-repeat: none;
    background-size: none;
    border: 5px solid #EDEDED;
    background-color: transparent;
    border-radius: 40px;
    padding: 30px 20px;
  }
  .feeBlock{
    padding: 10px;
    font-size: 16px;
    margin-bottom: 0;
    margin-top: -30px;
  }
  .topgap200Neg{
    margin-top: -945px;
  }
  .footer{
    margin-top: 500px;
  }
  .form-container{
    width: 100%;
  }
}